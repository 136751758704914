import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { WP_CONTENT_URL, WP_URL } from "../constants";
import { headers } from "next/dist/client/components/headers";

const defaultOptions = {
  watchQuery: {
    // fetchPolicy: "no-cache",
    errorPolicy: "ignore",
    mode: "no-cors",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
  fetchOptions: {
    mode: "no-cors",
  },
};

const cache = new InMemoryCache({
  resultCaching: false,
});

/**
 * The credentials: 'include' allows cookies to be automatically sent
 * along with the request 'include' because backend is on another domain.
 *
 * @see https://www.apollographql.com/docs/react/networking/authentication/#cookie
 */
const link = createHttpLink({
  // uri: "https://blog.scplaybook.com.au/graphql",
  // uri: 'http://3.27.33.185/graphql',
  // uri: `${WP_CONTENT_URL}/graphql`
  // uri: `http://scpb3/graphql`,
  // uri: 'https://scplaybook.com.au/index.php?graphql',
  uri: `${WP_URL}/index.php?graphql`,

  // uri: `http://13.239.14.106/graphql`,
});

const client = new ApolloClient({
  connectToDevTools: true,
  link,
  cache,
  //@ts-ignore
  defaultOptions,
});

export default client;
